<template>
  <v-card>
    <v-card-title>Редактирование устройства</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form v-model="isFormValid">
        <v-row class="mt-3">
          <v-col cols="6">
            <v-text-field label="Мерчант" outlined hide-details readonly :value="selectedMerchant.name"
              :rules="[rules.required]" />
          </v-col>

          <v-col cols="6">
            <v-select label="Тип устройства" :items="types" item-text="name" item-value="type" v-model="type"
              @change="onMachineTypeChange" outlined hide-details :rules="[rules.required]" />
          </v-col>

          <v-col cols="12">
            <v-text-field label="Адрес" v-model="address" outlined hide-details :rules="[rules.required]" />
          </v-col>

          <v-col cols="6">
            <v-text-field label="Процент с мерчента (+Каспи)" v-model="merchantFeePercent" outlined hide-details
              :rules="[rules.required]" />
          </v-col>

          <v-col cols="6">
            <v-text-field label="Абон.плата" v-model="monthlyFee" outlined hide-details
              :rules="[rules.required, rules.denominations]" />
          </v-col>

          <v-col cols="12">
            <v-text-field label="Номиналы" v-model="denominations" outlined hide-details :rules="[rules.required]" />
          </v-col>

          <v-col cols="6">
            <v-text-field label="WiFi название" v-model="wifiSsid" outlined hide-details />
          </v-col>

          <v-col cols="6">
            <v-text-field label="WiFi пароль" v-model="wifiPassword" outlined hide-details />
          </v-col>

          <v-col cols="12">
            <v-text-field label="Sim id" v-model="simId" outlined hide-details />
          </v-col>

          <v-col cols="12">
            <v-switch v-model="isDisabled" class="mt-0" label="Отключен" color="red"></v-switch>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-spacer />
      <v-btn @click="onCancel">Отмена</v-btn>
      <v-btn @click="onSave" color="primary">Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>
  
<script>
import axios from 'axios';
import { mapState } from "vuex";

export default {
  props: ['id'],

  data() {
    return {
      merchantName: '',
      type: null,
      address: "",
      merchantFeePercent: 0,
      denominations: "",
      monthlyFee: 0,
      wifiSsid: "",
      wifiPassword: "",
      simId: "",
      isDisabled: false,

      types: [
        { type: 1, name: "Автомойка" },
        { type: 2, name: "Силомер" },
        { type: 4, name: "Водомат" },
        { type: 5, name: "Ландромат" },
        { type: 6, name: "Массажное кресло" },
        { type: 7, name: "Кофеавтомат" },
        { type: 8, name: "Игровой автомат" },
      ],

      isFormValid: false,
      rules: {
        required: value => !!value || 'Обязательно к заполнению',
        denominations: value => {
          const pattern = /^[0-9]+(,[0-9]+)*$/;
          return pattern.test(value) || 'Номиналы должны быть перечисленны через запятую'
        },
      },
    }
  },

  computed: {
    ...mapState({
      selectedMerchant: (state) => state.merchants.selected,
    }),
  },

  methods: {
    onMachineTypeChange(value) {
      this.type = +value;
    },

    onCancel() {
      this.$emit('cancel');
    },

    onSave() {
      let machine = {
        id: this.id,
        merchantId: this.selectedMerchant.id,
        type: +this.type,
        address: this.address,
        merchantFeePercent: (+this.merchantFeePercent) * 0.01,
        monthlyFee: +this.monthlyFee,
        denominations: this.denominations,
        wifiSsid: this.wifiSsid,
        wifiPassword: this.wifiPassword,
        simId: this.simId,
        isDisabled: this.isDisabled,
      };

      axios.put(`${process.env.VUE_APP_API_BASE_URL}/api/machines`, machine)
        .then(() => {
          this.$emit('confirm');
        });
    },
  },

  created() {
    axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/machines/admin/${this.id}`)
      .then((r) => {
        this.type = r.data.type;
        this.address = r.data.address;
        this.merchantFeePercent = (r.data.merchantFeePercent * 100).toFixed(2);
        this.denominations = r.data.denominations;
        this.monthlyFee = r.data.monthlyFee;
        this.wifiSsid = r.data.wifiSsid;
        this.wifiPassword = r.data.wifiPassword;
        this.simId = r.data.simId;
        this.isDisabled = r.data.isDisabled;
      });
  },
}
</script>