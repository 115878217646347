<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" :mini-variant="mini" app clipped>
      <v-list dense nav>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.route"
          color="secondary"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app clipped-left color="primary">
      <v-app-bar-nav-icon
        color="white"
        @click.stop="toggleNavigation"
      ></v-app-bar-nav-icon>
      <v-toolbar-title
        class="headline font-weight-light mr-10 d-none d-sm-block"
      >
        <span class="white--text">VENDING ADMIN</span>
      </v-toolbar-title>

      <v-toolbar-items> </v-toolbar-items>
      <div class="flex-grow-1"></div>
      <v-btn text class="font-weight-regular" @click="logout">
        <span class="white--text d-none d-sm-block">Выход</span>
        <v-icon color="white" right>exit_to_app</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <Snackbar />
    <Progress />
  </v-app>
</template>

<script>
import Snackbar from "./Snackbar/Snackbar.vue";
import Progress from "./Progress/Progress.vue";

export default {
  components: { Snackbar, Progress },

  data() {
    return {
      drawer: this.$vuetify.breakpoint.mdAndUp,
      mini: false,

      items: [
        // {
        //   title: "Вендинги",
        //   icon: "coffee_maker",
        //   route: { name: "machines" },
        // },
        {
          title: "Мерченты",
          icon: "group",
          route: { name: "merchants" },
        },
      ],
    };
  },

  methods: {
    toggleNavigation() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.mini = !this.mini;
      } else {
        this.drawer = !this.drawer;
      }
    },

    logout() {
      localStorage.removeItem("token");
      location.reload();
    },
  },
};
</script>