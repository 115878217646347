<template>
  <v-card>
    <v-card-title class="headline grey lighten-2 d-flex justify-space-between">Перенос устройства {{ machine.address }}
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" class="mt-5">
          <v-autocomplete v-model="merchantId" :items="merchants" item-text="name" item-value="id" label="Новый мерчант"
            outlined hide-details />
        </v-col>
        <v-col cols="12">
          При переносе, все текущие данные (транзакции, аналитика и т.д.)
        </v-col>
        <v-col cols="12" class="my-n5">
          <v-radio-group v-model="dataAction">
            <v-radio label="Удалить" value="1"></v-radio>
            <v-radio label="Перенести" value="2"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="$emit('cancel')">Отмена</v-btn>
      <v-btn @click="onConfirmed" :disabled="!isValid" color="primary">Перенести</v-btn>
    </v-card-actions>
  </v-card>
</template>
  
<script>
import axios from 'axios';
import { mapState } from "vuex";

export default {
  props: ['machine'],

  data() {
    return {
      merchantId: null,
      dataAction: null,
    }
  },

  computed: {
    ...mapState({
      merchants: (state) => state.merchants.merchantsAll.filter(m => m.id !== state.merchants.selected.id),
    }),

    isValid() {
      return this.merchantId && this.dataAction;
    },
  },

  methods: {
    onConfirmed() {
      const body = { id: this.machine.id, merchantId: this.merchantId, dataAction: this.dataAction };
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/machines/change-merchant`, body)
        .then(() => {
          this.$emit('confirm');
        });
    }
  },
}
</script>