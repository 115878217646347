<template>
  <div>
    <v-card>
      <v-card-text>
        <v-data-table :headers="headers" :items="machines" :custom-sort="customSort">
          <template v-slot:item.type="{ item }">
            <span>{{ getMachineType(item.type) }}</span>
          </template>
          <template v-slot:item.isDisabled="{ item }">
            <span>{{ item.isDisabled ? 'Нет' : 'Да' }}</span>
          </template>
          <template v-slot:item.createDate="{ item }">
            <span>{{ item.createDate | date_ordered }}</span>
          </template>
          <template v-slot:item.launchDate="{ item }">
            <span>{{ item.launchDate | date_ordered }}</span>
          </template>
          <template v-slot:item.action="{ item }">
            <span><v-btn text icon @click="onEdit(item)">
                <v-icon>edit</v-icon>
              </v-btn>
              <v-btn text icon @click="onDelete(item)">
                <v-icon>delete_forever</v-icon>
              </v-btn>
              <v-btn text icon @click="onChangeMerchant(item)">
                <v-icon>shuffle</v-icon>
              </v-btn></span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialogEdit" v-if="dialogEdit" width="500" scrollable>
      <machine-edit :id="idToEdit" @cancel="dialogEdit = false" @confirm="onEditConfirm" />
    </v-dialog>

    <v-dialog v-model="dialogDelete" v-if="dialogDelete" width="500" scrollable>
      <machine-delete :machine="machineToDelete" @cancel="dialogDelete = false" @confirm="onDeleteConfirm" />
    </v-dialog>

    <v-dialog v-model="dialogToChangeMerchant" v-if="dialogToChangeMerchant" width="500" scrollable>
      <machine-change-merchant :machine="machineToChangeMerchant" @cancel="dialogToChangeMerchant = false"
        @confirm="onChangeMerchantConfirm" />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import MachineEdit from "../Machines/MachineEdit.vue";
import MachineDelete from "../Machines/MachineDelete.vue";
import MachineChangeMerchant from "../Machines/MachineChangeMerchant.vue";

export default {
  components: { MachineEdit, MachineDelete, MachineChangeMerchant },

  data() {
    return {
      dialogEdit: false,
      idToEdit: null,

      dialogDelete: false,
      machineToDelete: null,

      dialogToChangeMerchant: false,
      machineToChangeMerchant: null,
      headers: [
        { text: 'Тип', value: 'type', sortable: false },
        { text: 'Адрес', value: 'address' },
        { text: 'QR', value: 'code', sortable: false },
        { text: 'Включен', value: 'isDisabled', sortable: false },
        { text: 'Создан', value: 'createDate', sortable: false },
        { text: 'Запущен (QR)', value: 'launchDate', sortable: false },
        { text: 'Действия', value: 'action', sortable: false },],
    }
  },

  computed: {
    ...mapState({
      machines: (state) => state.machines.machines,
      selectedMerchant: (state) => state.merchants.selected,
    }),
  },

  methods: {
    ...mapActions("machines", ["fetchMachines"]),

    getMachineType(type) {
      if (type === 1) return "Автомойка";
      if (type === 2) return "Силомер";
      if (type === 3) return "Водомат";
      return "Вендинг";
    },

    onEdit(machine) {
      this.idToEdit = machine.id;
      this.dialogEdit = true;
    },

    onDelete(machine) {
      this.machineToDelete = machine;
      this.dialogDelete = true;
    },

    onChangeMerchant(machine) {
      this.machineToChangeMerchant = machine;
      this.dialogToChangeMerchant = true;
    },

    onEditConfirm() {
      this.fetchMachines();
      this.dialogEdit = false;
    },

    onDeleteConfirm() {
      this.fetchMachines();
      this.dialogDelete = false;
      this.machineToDelete = null;
    },

    onChangeMerchantConfirm() {
      this.fetchMachines();
      this.dialogToChangeMerchant = false;
      this.machineToChangeMerchant = null;
    },

    customSort(items, sortBy, sortDesc) {
      const descAsc = sortDesc[0] ? -1 : 1
      if (items.length && sortBy[0] === 'address') {
        for (let i = 1; i < items.length; i++) {
          var x = items[i]
          var j = i - 1
          while (j >= 0 && items[j].address.localeCompare(x.address, undefined, { numeric: true, sensitivity: 'base' }) === descAsc) {
            items[j + 1] = items[j]
            j = j - 1
          }
          items[j + 1] = x
        }
      }
      return items
    },
  },

  watch: {
    selectedMerchant() {
      this.fetchMachines();
    },
  },

  created() {
    this.fetchMachines();
  },
};
</script>