<template>
  <v-card>
    <v-card-text>
      <v-form v-model="isFormValid">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field label="Наименование ИП/ТОО" outlined hide-details v-model="merchant.name"
              :rules="[rules.required]"></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field label="Email" outlined hide-details v-model="merchant.email"
              :rules="[rules.required, rules.email]" @input="onEmailInput($event)">
            </v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field label="Город" outlined hide-details v-model="merchant.city" :rules="[rules.required]">
            </v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field label="Юр.адрес" outlined hide-details v-model="merchant.legalAddress"
              :rules="[rules.required]">
            </v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field label="БИН/ИИН" outlined hide-details v-model="merchant.bin"
              :rules="[rules.required, rules.bin]">
            </v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field label="Номер счета" outlined hide-details v-model="merchant.iban"
              :rules="[rules.required, rules.iban]">
            </v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field label="Контактное лицо" outlined hide-details v-model="merchant.contactPerson"
              :rules="[rules.required]"></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field label="Номер телефона" outlined hide-details v-model="merchant.phoneNumber"
              :rules="[rules.required, rules.phoneNumber]" @input="onPhoneInput($event)"></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-radio-group v-model="merchant.machineType" row @change="onMachineTypeChange($event)">
              <v-radio label="Автомойка" value="1"></v-radio>
              <v-radio label="Силомер" value="2"></v-radio>
              <v-radio label="Водомат" value="4"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field label="Кол-во устройств" outlined hide-details v-model="merchant.machinesCount"
              :rules="[rules.required]"></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field label="Префикс в названии" outlined hide-details v-model="merchant.machinePrefix">
            </v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field label="Абонентская плата" outlined hide-details v-model="merchant.monthlyFee"
              :rules="[rules.required]"></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field label="Комиссия с платежей" outlined hide-details v-model="merchant.merchantFeePercent"
              :rules="[rules.required]">
            </v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field label="Номиналы" outlined hide-details v-model="merchant.denominations"
              :rules="[rules.required, rules.denominations]"></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field label="Адрес доставки" outlined hide-details v-model="merchant.deliveryAddress"></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field label="Менеджер" outlined hide-details v-model="merchant.manager"></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="onCreate" :disabled="!isFormValid">Создать</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      merchant: {},
      isFormValid: false,
      rules: {
        required: value => !!value || 'Обязательно к заполнению',
        bin: value => {
          const pattern = /^\d{12}$/;
          return pattern.test(value) || 'БИН должен содержать 12 цифр';
        },
        iban: value => {
          const pattern = /^KZ\w{18}$/;
          return pattern.test(value) || 'IBAN должен содержать 20 символов';
        },
        phoneNumber: value => {
          const pattern = /^\+77\d{9}$/;
          return pattern.test(value) || 'Номер тел должен быть в формате +77xxxxxxxxx';
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
        denominations: value => {
          const pattern = /^[0-9]+(,[0-9]+)*$/;
          return pattern.test(value) || 'Номиналы должны быть перечисленны через запятую'
        },
      },
    }
  },


  methods: {
    onPhoneInput(value) {
      this.merchant.phoneNumber = value.replace(/\s+/g, '');
    },

    onEmailInput(value) {
      this.merchant.email = value.trim();
    },

    onMachineTypeChange(value) {
      value = +value;
      if (value === 1) { // Автомойка
        this.merchant.merchantFeePercent = 0.95;
        this.merchant.denominations = '100,200,300,400,500,600,700,800,900,1000';
      } else if (value === 2) { // Силомер
        this.merchant.merchantFeePercent = 0.95;
        this.merchant.denominations = '100,200,300,400,500,600,700,800,900,1000';
      } else if (value == 4) { // Водомат
        this.merchant.merchantFeePercent = 0.95;
        this.merchant.denominations = '10,50,60,80,100,190,300,400,500';
      }
    },

    onCreate() {
      const body = {
        name: this.merchant.name,
        email: this.merchant.email,
        bin: this.merchant.bin,
        iban: this.merchant.iban,
        legalAddress: this.merchant.legalAddress,
        contactPerson: this.merchant.contactPerson,
        phoneNumber: this.merchant.phoneNumber,
        city: this.merchant.city,
        machineType: +this.merchant.machineType,
        machinesCount: +this.merchant.machinesCount,
        machinePrefix: this.merchant.machinePrefix,
        monthlyFee: +this.merchant.monthlyFee,
        merchantFeePercent: (+this.merchant.merchantFeePercent) * 0.01,
        denominations: this.merchant.denominations,
        deliveryAddress: this.merchant.deliveryAddress,
        manager: this.merchant.manager,
      };
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/merchants`, body)
        .then(() => {
          this.$store.commit("snackbar/SHOW", "Данные успешно сохран");
          this.$router.push({ name: "merchants" })
        })
        .catch(e => {
          this.$store.commit("snackbar/SHOW", `Ошибка: ${e.response.data.Message}`);
        });
    },
  },
}
</script>