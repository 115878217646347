<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field v-model="name" label="Наименование ТОО/ИП" outlined hide-details />
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field v-model="email" label="Email" outlined hide-details />
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field v-model="password" label="Пароль" outlined hide-details />
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field v-model="bin" label="БИН" outlined hide-details />
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field v-model="iban" label="Номер счета" outlined hide-details />
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field v-model="wifiSsid" label="WiFi Ssid" outlined hide-details />
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field v-model="wifiPassword" label="WiFi Password" outlined hide-details />
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field v-model="contactPerson" label="Контактное лицо" outlined hide-details />
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field v-model="phoneNumber" label="Номер тел" outlined hide-details />
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field v-model="city" label="Город" outlined hide-details />
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field v-model="manager" label="Менеджер" outlined hide-details />
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field v-model="deliveryAddress" label="Адрес доставки" outlined hide-details />
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field v-model="createDate" label="Дата создания" outlined hide-details readonly />
        </v-col>

        <v-col cols="12" md="4">
          <v-row justify-space-between>
            <v-col>
              <v-switch v-model="isDisabled" label="Отключен" outlined hide-details />
            </v-col>
            <v-col>
              <v-switch v-model="isLocked" label="Заблокирован" outlined hide-details />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="4">
            <v-btn @click="downloadProfile">Скачать анкету</v-btn>
        </v-col>

        <v-col cols="12" md="4">
          <v-btn @click="downloadTransportList">Скачать транспортный лист</v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :disabled="!isChanged" @click="onSave">Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";
import axios from 'axios';

export default {
  data() {
    return {
      name: "",
      email: "",
      password: "",
      bin: "",
      iban: "",
      wifiSsid: "",
      wifiPassword: "",
      contactPerson: "",
      phoneNumber: "",
      city: "",
      isDisabled: false,
      isLocked: false,
      deliveryAddress: "",
      manager: "",
      createDate: "",
    }
  },

  computed: {
    ...mapState({
      merchant: (state) => state.merchants.selected,
    }),

    isChanged() {
      return this.name != this.merchant.name ||
        this.email != this.merchant.email ||
        this.password != this.merchant.password ||
        this.bin != this.merchant.bin ||
        this.iban != this.merchant.iban ||
        this.wifiSsid != this.merchant.wifiSsid ||
        this.wifiPassword != this.merchant.wifiPassword ||
        this.contactPerson != this.merchant.contactPerson ||
        this.phoneNumber != this.merchant.phoneNumber ||
        this.city != this.merchant.city ||
        this.isDisabled != this.merchant.isDisabled ||
        this.isLocked != this.merchant.isLocked ||
        this.createDate != moment(this.merchant.createDate).format('YYYY-MM-DD') ||
        (this.deliveryAddress != this.merchant.deliveryAddress && this.deliveryAddress.length > 0) ||
        (this.manager != this.merchant.manager && this.manager.length > 0);
    },
  },

  methods: {
    ...mapActions("merchants", ["fetchMerchants", "downloadProfile", "downloadTransportList"]),

    resetMerchantData() {
      this.name = this.merchant.name;
      this.email = this.merchant.email;
      this.password = this.merchant.password;
      this.bin = this.merchant.bin;
      this.iban = this.merchant.iban;
      this.wifiSsid = this.merchant.wifiSsid;
      this.wifiPassword = this.merchant.wifiPassword;
      this.contactPerson = this.merchant.contactPerson;
      this.phoneNumber = this.merchant.phoneNumber;
      this.city = this.merchant.city;
      this.isDisabled = this.merchant.isDisabled;
      this.isLocked = this.merchant.isLocked;
      this.createDate = moment(this.merchant.createDate).format('YYYY-MM-DD');
      this.deliveryAddress = this.merchant.deliveryAddress;
      this.manager = this.merchant.manager;
    },

    onSave() {
      const body = {
        id: this.merchant.id,
        name: this.name,
        email: this.email,
        password: this.password,
        bin: this.bin,
        iban: this.iban,
        wifiSsid: this.wifiSsid,
        wifiPassword: this.wifiPassword,
        contactPerson: this.contactPerson,
        phoneNumber: this.phoneNumber,
        city: this.city,
        isDisabled: this.isDisabled,
        isLocked: this.isLocked,
        deliveryAddress: this.deliveryAddress,
        manager: this.manager,
      };

      axios.put(`${process.env.VUE_APP_API_BASE_URL}/api/merchants`, body)
        .then(() => {
          this.fetchMerchants();
        });
    },
  },

  watch: {
    merchant() {
      this.resetMerchantData();
    },
  },

  created() {
    this.resetMerchantData();
  }
};
</script>