<template>
  <v-card>
    <v-card-title class="headline grey lighten-2 d-flex justify-space-between">Подтверждение
    </v-card-title>
    <v-card-text class="mt-4">
      <div>Удалить устройство {{ machine.address }}?</div>
      <!-- <div>
        При удалении, транзакции устройства
        <v-radio-group v-model="statementsAction">
          <v-radio label="Удалить" value="1"></v-radio>
          <v-radio label="Перенести" value="2"></v-radio>
        </v-radio-group>
      </div> -->
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="$emit('cancel')">Отмена</v-btn>
      <v-btn @click="onConfirmed" color="primary">Удалить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  props: ['machine'],

  data() {
    return {
      statementsAction: null,
    }
  },

  methods: {
    onConfirmed() {
      axios.delete(`${process.env.VUE_APP_API_BASE_URL}/api/machines/${this.machine.id}`)
        .then(() => {
          this.$emit('confirm');
        });
    }
  },
}
</script>